.text {
  font-size: 17px;
  line-height: 23px;
}

.largeText {
  font-size: 25px;
  line-height: 30px;
  perspective: 250px;
}

.clampedTextSmall, .clampedTextMedium, .clampedTextLarge {
  display: -webkit-box;
  overflow: hidden;
}

.clampedTextSmall {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}

.clampedTextMedium {
  -webkit-line-clamp: 11;
  line-clamp: 11;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}

.clampedTextLarge {
  -webkit-line-clamp: 20;
  line-clamp: 20;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}

.sidebar {
  height: 924px;
}

@media (max-width: 910px) {
  .hideOnSmallScreen {
    display: none !important;
  }
}

.clampedTextSmall{
  display: -webkit-box;
  -webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
  	overflow: hidden;
}
