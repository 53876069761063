.centerSection {
  width: 1300px;
  padding: 0 100px;
}

@media (max-width: 1080px) {
  .centerSection {
    padding-right: 20px;
  }

  .extraLinks {
    display: none !important;
  }
}