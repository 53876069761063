.gallery {
  display: grid;
}

.gallery::before {
  content: '';
  width: 0;
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.gallerySquare {
  grid-column: 1;
  grid-row: 1;
}
